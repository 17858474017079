import { useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { detailsBoxStyle, rowStyle } from "../utils";
import { useIntl } from "react-intl";
import { ACTIVE, IN_PROGRESS, ESCALATED } from "../../../utils/constants";
import {
  DarkModeContext,
  AlarmBullet,
  useAlarm,
} from "@datwyler/shared-components";

const AlertTab = (props) => {
  const { siteDetails, tenantId } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const { fetchAlarmData, fetchAlarms } = useAlarm();

  useEffect(() => {
    if (siteDetails) {
      fetchAlarms({
        variables: {
          tenantId: tenantId,
          filter: {
            site: { id: siteDetails.site.id },
            status: [ACTIVE, IN_PROGRESS, ESCALATED],
          },
          page: { number: 0, size: 999999 },
          sort: ["deviceId"],
        },
      });
    }
  }, [siteDetails]);

  const typeTypographyStyle = {
    width: "160px",
    color: colors.siteDetailsFont2,
    fontFamily: "NotoSans-Medium",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0.02px",
    lineHeight: "16px",
  };

  const dateTypographyStyle = {
    width: "60px",
    color: colors.siteDetailsFont1,
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
    letterSpacing: "0.02px",
    lineHeight: "16px",
  };

  let currDeviceId = "";

  return (
    <Box sx={{ marginTop: "24px" }}>
      {fetchAlarmData?.alarms?.alarms?.map((alarm, index) => {
        const alertTime = new Date(alarm.time * 1000);
        let isShowDeviceName = false;
        if (alarm.device.id !== currDeviceId) {
          isShowDeviceName = true;
          currDeviceId = alarm.device.id;
        }

        return (
          <>
            {isShowDeviceName && (
              <Typography
                sx={{
                  color: colors.siteDetailsDeviceName,
                  fontFamily: "NotoSans-SemiBold",
                  fontSize: "14px",
                  fontWeight: "600",
                  letterSpacing: "0.01px",
                  lineHeight: "20px",
                  marginBottom: "16px",
                }}
              >
                {alarm?.device?.name}
              </Typography>
            )}
            <Box
              sx={{
                ...rowStyle,
                display: "flex",
                alignItems: "center",
              }}
              key={"alarm_" + index}
            >
              <AlarmBullet severity={alarm.severity} width={7} height={7} />
              <Box sx={detailsBoxStyle}>
                <Typography sx={typeTypographyStyle}>
                  {alarm?.values?.attribute_name
                    ? intl.formatMessage({
                        id: alarm.values.attribute_name,
                      })
                    : ""}
                </Typography>
              </Box>
              <Box sx={{ ...detailsBoxStyle, marginRight: "49px" }}>
                <Typography sx={dateTypographyStyle}>
                  {formatTime(alertTime.getHours()) +
                    ":" +
                    formatTime(alertTime.getMinutes()) +
                    ":" +
                    formatTime(alertTime.getSeconds())}
                </Typography>
              </Box>
              <Box sx={detailsBoxStyle}>
                <Typography sx={dateTypographyStyle}>
                  {formatTime(alertTime.getDate()) +
                    "/" +
                    formatTime(alertTime.getMonth() + 1) +
                    "/" +
                    formatTime(alertTime.getFullYear() % 100)}
                </Typography>
              </Box>
            </Box>
          </>
        );
      })}
    </Box>
  );
};

const formatTime = (time) => {
  if (time < 10) {
    return "0" + time;
  }
  return time;
};

export default AlertTab;
