import { useEffect, useState, useContext } from "react";
import SideMenu from "../components/Sites/SideMenu";
import AddEditLocation from "../components/Sites/AddEditLocation";
import LocationFilters from "../components/Sites/LocationFilters";
import Box from "@mui/material/Box";
import StyleContext from "../context/StyleContext";
import LocationDetails from "../components/Sites/LocationDetails";
import SiteDetails from "../components/Sites/SiteDetails";
import {
  useLocation,
  useLoadingGif,
  useDevice,
  TenantIdContext,
  Map,
  UserContext,
  hasAccess,
} from "@datwyler/shared-components";
import { ACTIVE } from "../components/utils/constants";
import {
  HEADERHEIGHT,
  HEADERHEIGHTMOBILE,
  useScreenOptions,
} from "@datwyler/mfe-shared-components";

const leftMargin = 24;

const Sites = () => {
  const { tenantId }: any = useContext(TenantIdContext);
  const zoom = 5;
  const { isFetchLoading, fetchLocationData, fetchLocations } = useLocation();
  const { isMobileScreen } = useScreenOptions();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const [selectedLocationForEdit, setSelectedLocationForEdit] = useState(null);
  const [filters, setFilters] = useState({
    country: "",
  });
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [mapProps, setMapProps] = useState({
    center: {
      // Singapore coordinates
      // lat: 1.3521,
      // lng: 103.8198,
      lat: 1.3521,
      lng: 103.8198,
    },
    zoom: zoom,
  });

  const [isLocationDetailsOpen, setIsLocationDetailsOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedSiteData, setSelectedSiteData] = useState(null);
  const {
    isFetchLoading: isDeviceFetchLoading,
    fetchDeviceData,
    fetchDevices,
  } = useDevice();

  const { user }: any = useContext(UserContext);
  const canManageSite = hasAccess(user.role, "manage_site");
  let isFirstLoad = true;

  useEffect(() => {
    if (fetchDeviceData) {
      setSelectedSiteData(getSiteData(selectedSite));
    }
  }, [fetchDeviceData]);

  const handleViewSiteDetails = (site) => {
    setIsLocationDetailsOpen(false);
    setSelectedSite(site);
    if (selectedSite?.id === site.id) {
      setSelectedSiteData(getSiteData(site));
    } else {
      fetchDevices({
        variables: {
          tenantId: tenantId,
          page: { number: 0, size: 999999 },
          filter: [`status:${ACTIVE},siteId:${site.id}`],
        },
      });
    }
  };

  const getSiteData = (site) => {
    const siteData = {
      site: site,
      location: selectedLocation,
      details: {},
      devices: [],
    };

    siteData.details = {
      numDevices: fetchDeviceData?.devices?.devices?.length || 0,
      unacknowledgedAlerts: 0,
    };

    siteData.devices = fetchDeviceData?.devices?.devices || [];
    return siteData;
  };

  useEffect(() => {
    fetchLocations({
      variables: { tenantId: tenantId },
    });
  }, [tenantId]);

  useEffect(() => {
    if (fetchLocationData?.locations) {
      if (isFirstLoad) {
        setMapProps({
          center: {
            lat: fetchLocationData.locations[0]?.latitude || 1.3521,
            lng: fetchLocationData.locations[0]?.longitude || 103.8198,
          },
          zoom: zoom,
        });
        isFirstLoad = false;
      }
    }
  }, [fetchLocationData]);

  useEffect(() => {
    setIsLoading(isFetchLoading || (isDeviceFetchLoading && !selectedSiteData));
  }, [isFetchLoading, isDeviceFetchLoading]);

  useEffect(() => {
    if (fetchLocationData?.locations) {
      const tempLocations = fetchLocationData.locations.filter((loc) => {
        return (
          !filters.country ||
          loc.country.id === filters.country ||
          filters.country === "all"
        );
      });
      setFilteredLocations(tempLocations);
    }
  }, [fetchLocationData, filters]);

  useEffect(() => {
    setIsLocationDetailsOpen(!!selectedLocation);
  }, [selectedLocation]);

  const handleEditLocation = (loc) => {
    setSelectedLocationForEdit(loc);
  };

  const handleBackSiteDetails = () => {
    setSelectedSiteData(null);
    setTimeout(() => {
      setIsLocationDetailsOpen(true);
    }, 100);
  };

  return (
    <StyleContext.Provider
      value={{ leftMargin: leftMargin, setIsLoading: setIsLoading }}
    >
      <LoadingGif />
      <Box sx={{ position: "absolute", left: leftMargin, top: 32 }}>
        <AddEditLocation
          leftMargin={leftMargin}
          selectedLocationForEdit={selectedLocationForEdit}
          setSelectedLocationForEdit={setSelectedLocationForEdit}
          setMapProps={setMapProps}
          tenantId={tenantId}
          canManageSite={canManageSite}
        />
        <LocationFilters
          locations={fetchLocationData?.locations}
          filters={filters}
          setFilters={setFilters}
        />
      </Box>
      {fetchLocationData?.locations &&
        fetchLocationData.locations.length > 0 && (
          <Box sx={{ position: "absolute", left: leftMargin, top: 100 }}>
            <SideMenu
              locations={filteredLocations}
              setMapProps={setMapProps}
              setSelectedLocation={setSelectedLocation}
            />
          </Box>
        )}
      <Map
        locations={fetchLocationData?.locations}
        mapProps={mapProps}
        setSelectedLocation={setSelectedLocation}
        height={`calc(100vh - ${
          isMobileScreen ? HEADERHEIGHTMOBILE : HEADERHEIGHT
        }px)`}
      />
      <LocationDetails
        isOpen={isLocationDetailsOpen}
        location={selectedLocation}
        handleClose={() => setSelectedLocation(null)}
        handleViewSiteDetails={handleViewSiteDetails}
        handleEditLocation={handleEditLocation}
        canManageSite={canManageSite}
      />
      <SiteDetails
        isOpen={!!selectedSiteData}
        siteDetails={selectedSiteData}
        handleBack={handleBackSiteDetails}
        handleClose={() => {
          setSelectedLocation(null);
          setSelectedSiteData(null);
        }}
        tenantId={tenantId}
        fetchDevices={fetchDevices}
      />
    </StyleContext.Provider>
  );
};

export default Sites;
