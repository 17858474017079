export const textFieldInputProps = {
  sx: {
    height: "48px",
    py: "14px",
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
  },
};

export const inputLabelProps = {
  sx: {
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
    letterSpacing: "0.13px",
    lineHeight: "19px",
  },
};

export const getInputRulesRequired = (intl) => {
  return {
    required: {
      value: true,
      message: intl.formatMessage({ id: "validation_required" }),
    },
  };
};

export const getInputRulesLongitude = (intl) => {
  return {
    min: {
      value: -180,
      message: intl.formatMessage({ id: "validation_min_longitude" }),
    },
    max: {
      value: 180,
      message: intl.formatMessage({ id: "validation_max_longitude" }),
    },
  };
};

export const getInputRulesLatitude = (intl) => {
  return {
    min: {
      value: -90,
      message: intl.formatMessage({ id: "validation_min_latitude" }),
    },
    max: {
      value: 90,
      message: intl.formatMessage({ id: "validation_max_latitude" }),
    },
  };
};

export const isDeviceInSites = (allSites = [], allDeviceData) => {
  if (!allSites) return false;
  if (allSites.length > 0) {
    const allSiteIds = allSites.map((site) => site.id);
    return allDeviceData?.devices?.devices?.some((device) =>
      allSiteIds?.includes(device.site?.id)
    );
  }

  return false;
};
