import { useState, useEffect, useContext } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { ACTIVE } from "../utils/constants";
import {
  DarkModeContext,
  getScrollBarSx,
  LocationPinIcon,
  ArrowRight,
  AlarmBullet,
} from "@datwyler/shared-components";
import { HEADERHEIGHT } from "@datwyler/mfe-shared-components";

const SideMenu = (props: any) => {
  const { locations, setMapProps, setSelectedLocation } = props;
  const [lastActiveLocationIndex, setLastActiveLocationIndex] = useState(0);
  const { colors }: any = useContext(DarkModeContext);

  const handleOnClick = (location) => {
    setMapProps({
      center: {
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      },
    });
  };

  useEffect(() => {
    let temp = 0;
    locations?.forEach((loc, index) => {
      if (loc.status == ACTIVE) temp = index;
    });
    setLastActiveLocationIndex(temp);
  }, [locations]);

  const paperStyle = {
    background: colors.sideMenuBg,
    backdropFilter: "blur(8px)",
    position: "relative",
    width: 400,
    zIndex: 10,
    maxHeight: `calc(100vh - ${HEADERHEIGHT}px - 100px - 32px)`,
    overflowY: "auto",
    borderRadius: "8px",
    ...getScrollBarSx(colors),
  };

  const getLocationsMenu = () => {
    const textWidth = 282;

    return (
      <Box sx={{ py: "10px" }}>
        {locations?.map((location, index) => {
          if (location.status === ACTIVE) {
            return (
              <Box
                key={location.name}
                sx={{ cursor: "pointer" }}
                onClick={() => handleOnClick(location)}
              >
                <Box
                  sx={{
                    backgroundColor: "unset",
                    mx: "10px",
                  }}
                >
                  <Box
                    sx={{
                      px: "6px",
                      paddingTop: "14px",
                      paddingBottom: "16px",
                      "&:hover": {
                        backgroundColor: "rgba(0,0,0,0.08)",
                      },
                      borderRadius: "8px",
                      display: "inline-flex",
                    }}
                  >
                    <Box
                      sx={{
                        display: "inline-flex",
                        height: "40px",
                        width: "40px",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "20px",
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <LocationPinIcon />
                    </Box>
                    <Box
                      sx={{
                        display: "inline-flex",
                        verticalAlign: "top",
                        maxWidth: textWidth,
                        marginLeft: "16px",
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: "inline-flex",
                            width: textWidth - 6,
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "NotoSans-SemiBold",
                              fontSize: "16px",
                              fontWeight: 600,
                              letterSpacing: "0.01px",
                              lineHeight: "22px",
                              color: colors.sideMenuTitle,
                            }}
                          >
                            {location.name}
                          </Typography>
                          <Box
                            sx={{
                              marginLeft: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AlarmBullet severity={location.highestSeverity} />
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              marginTop: 0,
                              fontFamily: "NotoSans-Regular",
                              fontSize: "14px",
                              letterSpacing: "0.01px",
                              lineHeight: "19px",
                              color: colors.sideMenuFont,
                            }}
                          >
                            {location?.country?.name}
                          </Typography>
                        </Box>
                        <Box id="sites" sx={{ marginTop: "8px" }}>
                          {location?.sites?.map((site, index) => {
                            if (site.status === ACTIVE) {
                              return (
                                <Box
                                  key={site.name}
                                  sx={{
                                    display: "inline-flex",
                                    maxWidth: textWidth - 32,
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      marginTop: 0,
                                      fontFamily: "NotoSans-Regular",
                                      fontSize: "14px",
                                      letterSpacing: "0.01px",
                                      lineHeight: "23px",
                                      color: colors.sideMenuFont,
                                    }}
                                  >
                                    {site.name}
                                  </Typography>
                                  {index < location.sites.length - 1 && (
                                    <Divider
                                      orientation="vertical"
                                      sx={{ mx: "13.5px", height: "10px" }}
                                    />
                                  )}
                                </Box>
                              );
                            }
                          })}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: "inline-flex" }}>
                    <IconButton onClick={() => setSelectedLocation(location)}>
                      <ArrowRight fill={colors.iconColor} />
                    </IconButton>
                  </Box>
                  {index < lastActiveLocationIndex && (
                    <Box sx={{ width: "368px", margin: "auto" }}>
                      <Divider />
                    </Box>
                  )}
                </Box>
              </Box>
            );
          }
        })}
      </Box>
    );
  };

  return (
    <Paper elevation={3} sx={paperStyle}>
      {getLocationsMenu()}
    </Paper>
  );
};

export default SideMenu;
