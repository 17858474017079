import { Fragment, useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  typographyStyle,
  typography2Style,
  statusLabelBoxStyle,
  detailsBoxStyle,
  rowStyle,
} from "../utils";
import { ACTIVE, LAST_CONNECTION } from "../../../utils/constants";
import { useIntl } from "react-intl";
import {
  DarkModeContext,
  getRequiredDateFormat,
  isFloat,
} from "@datwyler/shared-components";

const StatusTab = (props) => {
  const { siteDetails } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const font1Style = { ...typographyStyle, color: colors.siteDetailsFont1 };
  const font2Style = { ...typography2Style, color: colors.siteDetailsFont2 };

  return (
    <Box sx={{ marginTop: "24px" }}>
      {siteDetails?.devices.map((device: any) => {
        if (device.status === ACTIVE && device.telemetry) {
          // place status and last_connection at the beginning
          const telemetries = device.telemetry.filter(
            (data) => data.name !== "last_connection" && data.name !== "status"
          );
          telemetries.sort((a, b) => a.seq - b.seq);
          const lastConnection = device.telemetry.find(
            (data) => data.name === "last_connection"
          );
          const status = device.telemetry.find(
            (data) => data.name === "status"
          );
          if (lastConnection) telemetries.unshift(lastConnection);
          if (status) telemetries.unshift(status);

          return (
            <Fragment key={device.name}>
              <Typography
                sx={{
                  color: colors.siteDetailsDeviceName,
                  fontFamily: "NotoSans-SemiBold",
                  fontSize: "14px",
                  fontWeight: "600",
                  letterSpacing: "0.01px",
                  lineHeight: "20px",
                  marginBottom: "16px",
                }}
              >
                {device.name}
              </Typography>
              {telemetries.map((data) => {
                let value = data.value;

                // Check if it's a float and round off to 2 decimal places
                if (!!value && isFloat(value)) {
                  value = parseFloat(value).toFixed(2);
                }

                if (!!value && isNaN(value) && !isFloat(value))
                  value = intl.formatMessage({ id: value?.toLowerCase() });

                return (
                  <Box sx={rowStyle} key={data.name}>
                    <Box sx={statusLabelBoxStyle}>
                      <Typography sx={font1Style}>
                        {intl.formatMessage({ id: data.name })}
                      </Typography>
                    </Box>
                    <Box sx={detailsBoxStyle}>
                      <Typography sx={font2Style}>
                        {data.name === LAST_CONNECTION
                          ? getRequiredDateFormat(
                              parseInt(data.value),
                              "DD-MM-YYYY HH:mm:ss"
                            )
                          : value}{" "}
                        {data.unit}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Fragment>
          );
        }
      })}
    </Box>
  );
};

export default StatusTab;
