import { useContext, useState, SyntheticEvent, useEffect } from "react";
import DialogContent from "@mui/material/DialogContent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import DetailsContentWrapper from "../../utils/DetailsContentWrapper";
import StyleContext from "../../../context/StyleContext";
import CustomDialogTitle from "./components/CustomDialogTitle";
import DetailTab from "./components/DetailTab";
import StatusTab from "./components/StatusTab";
import AlertTab from "./components/AlertTab";
import { useIntl } from "react-intl";
import AttachmentTab from "./components/AttachmentTab";
import { DarkModeContext, getScrollBarSx } from "@datwyler/shared-components";
import { ACTIVE } from "../../utils/constants";

let fetchDataInterval;

const SiteDetails = (props) => {
  const {
    isOpen,
    handleClose,
    siteDetails,
    handleBack,
    tenantId,
    fetchDevices,
  } = props;
  const { leftMargin }: any = useContext(StyleContext);
  const [activeTab, setActiveTab] = useState(0);
  const [currSiteDetails, setCurrSiteDetails] = useState({
    site: {},
    devices: [],
  });
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    if (siteDetails) {
      setCurrSiteDetails(siteDetails);
      // fetch data every 15sec
      clearInterval(fetchDataInterval);
      if (siteDetails.site?.id)
        fetchDataInterval = setInterval(
          () => intervalFetchData(tenantId),
          15000
        );
    } else {
      clearInterval(fetchDataInterval);
    }
  }, [siteDetails]);

  useEffect(() => {
    return () => {
      clearInterval(fetchDataInterval);
    };
  }, []);

  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const intervalFetchData = (tenantId) => {
    fetchDevices({
      variables: {
        tenantId: tenantId,
        page: { number: 0, size: 999999 },
        filter: [`status:${ACTIVE},siteId:${siteDetails.site.id}`],
      },
    });
  };

  const tabStyle = {
    backgroundColor: "unset",
    color: colors.siteDetailsTabFont,
    fontFamily: "NotoSans-Medium",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0.01px",
    lineHeight: "20px",
    textTransform: "unset",
    padding: "6px 8px",
    minWidth: "76px",
    minHeight: 0,
    "&.Mui-selected": {
      backgroundColor: colors.brand200,
      color: colors.blueGray700,
      borderRadius: "8px",
    },
  };

  return (
    <DetailsContentWrapper
      isOpen={isOpen}
      handleClose={(e) => {
        handleChangeTab(e, 0);
        clearInterval(fetchDataInterval);
        handleClose();
      }}
      leftMargin={leftMargin}
    >
      <CustomDialogTitle
        site={currSiteDetails?.site}
        handleBack={handleBack}
        devices={currSiteDetails?.devices}
      />
      <Divider sx={{ borderColor: colors.dividerColor }} />
      <DialogContent
        sx={{
          paddingBottom: "8px",
          paddingTop: "16px",
          ...getScrollBarSx(colors),
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "unset",
            },
          }}
          sx={{
            minHeight: 0,
          }}
        >
          <Tab
            label={intl.formatMessage({ id: "detail" })}
            disableRipple
            sx={tabStyle}
          />
          <Tab
            label={intl.formatMessage({ id: "status" })}
            disableRipple
            sx={tabStyle}
          />
          <Tab
            label={intl.formatMessage({ id: "alert" })}
            disableRipple
            sx={tabStyle}
          />
          <Tab
            label={intl.formatMessage({ id: "attachments" })}
            disableRipple
            sx={tabStyle}
          />
        </Tabs>
        {activeTab == 0 && <DetailTab siteDetails={currSiteDetails} />}
        {activeTab == 1 && <StatusTab siteDetails={currSiteDetails} />}
        {activeTab == 2 && (
          <AlertTab siteDetails={currSiteDetails} tenantId={tenantId} />
        )}
        {activeTab == 3 && (
          <AttachmentTab siteDetails={currSiteDetails} tenantId={tenantId} />
        )}
      </DialogContent>
    </DetailsContentWrapper>
  );
};

export default SiteDetails;
