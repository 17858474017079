import { useEffect, useState, useContext } from "react";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import Divider from "@mui/material/Divider";
import CustomDialogTitle from "./components/CustomDialogTitle";
import CustomDialogContent from "./components/CustomDialogContent";
import CustomDialogActions from "./components/CustomDialogActions";
import DetailsContentWrapper from "../../utils/DetailsContentWrapper";
import { ACTIVE, DEACTIVATED } from "../../utils/constants";
import { isDeviceInSites } from "./utils";
import {
  BasicButton,
  AddIcon,
  useCountry,
  useSnackbar,
  useLocation,
  useLoadingGif,
  useDevice,
  DarkModeContext,
} from "@datwyler/shared-components";

const defaultValues = {
  name: "",
  longitude: "",
  latitude: "",
  country: { id: null },
  sites: [],
};

const AddEditLocation = (props: any) => {
  const {
    leftMargin,
    selectedLocationForEdit,
    setSelectedLocationForEdit,
    setMapProps,
    tenantId,
    canManageSite,
  } = props;
  const [isShowModal, setIsShowModal] = useState(false);
  const intl = useIntl();
  const form = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { loading: isCountriesLoading, data: countriesData } = useCountry();
  const {
    isAddLocationLoading,
    addLocationResponseData,
    addLocation,
    resetAddLocationData,
    isUpdateLocationLoading,
    updateLocationResponseData,
    updateLocation,
    resetUpdateLocationData,
  } = useLocation();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const { fetchDeviceData: allDeviceData, fetchDevices: fetchAllDevices } =
    useDevice();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    fetchAllDevices({
      variables: {
        tenantId: tenantId,
        page: { number: 0, size: 999999 },
        filter: [`status:${ACTIVE}`],
      },
    });
  }, [tenantId]);

  useEffect(() => {
    setIsLoading(isAddLocationLoading || isUpdateLocationLoading);
  }, [isUpdateLocationLoading, isAddLocationLoading]);

  useEffect(() => {
    if (selectedLocationForEdit) {
      setIsShowModal(true);
      form.reset(selectedLocationForEdit);
    }
  }, [selectedLocationForEdit]);

  useEffect(() => {
    if (addLocationResponseData || updateLocationResponseData) {
      let msg = "add_location_success";
      let variant = "success";

      if (selectedLocationForEdit) {
        if (
          updateLocationResponseData?.updateLocation?.location?.status ===
          DEACTIVATED
        ) {
          msg = "success_del";
          variant = "warning";
        } else {
          msg = "update_setting_success";
          setMapProps({
            center: {
              lat: updateLocationResponseData?.updateLocation?.location
                ?.latitude,
              lng: updateLocationResponseData?.updateLocation?.location
                ?.longitude,
            },
          });
        }
      } else {
        // for add location
        setMapProps({
          center: {
            lat: addLocationResponseData?.addLocation?.location?.latitude,
            lng: addLocationResponseData?.addLocation?.location?.longitude,
          },
        });
      }
      enqueueSnackbar(intl.formatMessage({ id: msg }), {
        variant: variant,
      });
      resetUpdateLocationData();
      resetAddLocationData();
      handleCloseModal();
    }
  }, [addLocationResponseData, updateLocationResponseData]);

  const handleToggleModal = () => {
    setIsShowModal(!isShowModal);
  };

  const handleCloseModal = () => {
    setSelectedLocationForEdit(null);
    resetFormToDefault();
    setIsShowModal(false);
  };

  const resetFormToDefault = () => {
    form.reset(defaultValues);
  };

  const handleSubmit = async () => {
    const sites = form.getValues("sites");
    form.clearErrors();

    let isValid = await form.trigger();
    sites.forEach((site, index) => {
      const fieldName = `sites_${index}`;
      if (!site.name) {
        form.setError(`${fieldName}_name`, {
          type: "required",
          message: intl.formatMessage({ id: "validation_required" }),
        });
        isValid = false;
      }
    });

    if (isValid) {
      const submitValues = form.getValues();
      transformBeforeSend(submitValues, false);

      if (!selectedLocationForEdit)
        addLocation({
          variables: { input: submitValues },
        });
      else
        updateLocation({
          variables: { input: submitValues },
        });
    }
  };

  const transformBeforeSend = (submitValues, isDelete) => {
    submitValues.status = isDelete ? DEACTIVATED : ACTIVE;
    submitValues.tenant = { id: tenantId };

    if (countriesData.countries) {
      const country = countriesData.countries.find(
        (country) => country.id === submitValues.country.id
      );
      submitValues.country = { id: country.id };
    }

    if (!submitValues.sites) submitValues.sites = [];
    submitValues.longitude = parseFloat(submitValues.longitude);
    submitValues.latitude = parseFloat(submitValues.latitude);

    // remove the highestSeverity because backend LocationRequest do not expect an highestSeverity.
    if (Object.keys(submitValues).find((key) => key === "highestSeverity")) {
      delete submitValues.highestSeverity;
    }

    // handling sites (when deleting a site, set the status to deactivated)
    if (selectedLocationForEdit) {
      const existingSites = JSON.parse(
        JSON.stringify(selectedLocationForEdit.sites)
      );
      if (existingSites) {
        existingSites.forEach((site) => {
          if (!submitValues.sites.find((newSite) => newSite.id === site.id)) {
            // If user removed site before saving/deleting
            site.status = DEACTIVATED;
            submitValues.sites.push(site);
          } else if (isDelete) {
            const index = submitValues.sites.findIndex(
              (newSite) => newSite.id === site.id
            );
            const tempSites = JSON.parse(JSON.stringify(submitValues.sites));
            tempSites[index].status = DEACTIVATED;
            submitValues.sites = tempSites;
          }
        });
      }
    }
    return submitValues;
  };

  const handleDeleteLocation = (location) => {
    if (isDeviceInSites(location.sites, allDeviceData)) {
      enqueueSnackbar(intl.formatMessage({ id: "error_unable_delete" }), {
        variant: "error",
      });
      return;
    } else {
      const locationRequest = { ...location };
      transformBeforeSend(locationRequest, true);
      updateLocation({
        variables: { input: locationRequest },
      });
    }
  };

  return (
    <>
      <LoadingGif />
      {canManageSite && (
        <BasicButton
          variant="contained"
          sx={{
            zIndex: 10,
            minWidth: "127px",
            py: "10px",
            borderRadius: "8px",
          }}
          onClick={handleToggleModal}
        >
          <AddIcon />
          <Typography
            sx={{
              marginLeft: "10px",
              color: "#FFFFFF",
              fontFamily: "NotoSans-Medium",
              fontSize: "14px",
              fontWeight: 500,
              letterSpacing: 0,
              lineHeight: "20px",
            }}
          >
            {intl.formatMessage({ id: "add_new" })}
          </Typography>
        </BasicButton>
      )}
      <DetailsContentWrapper
        isOpen={isShowModal}
        handleClose={handleCloseModal}
        leftMargin={leftMargin}
      >
        <CustomDialogTitle
          handleCloseModal={handleCloseModal}
          selectedLocationForEdit={selectedLocationForEdit}
        />
        <Divider sx={{ borderColor: colors.dividerColor }} />
        <CustomDialogContent
          form={form}
          countries={countriesData?.countries}
          isCountriesLoading={isCountriesLoading}
          selectedLocationForEdit={selectedLocationForEdit}
          allDeviceData={allDeviceData}
        />
        <CustomDialogActions
          form={form}
          handleSubmit={handleSubmit}
          isEdit={!!selectedLocationForEdit}
          handleDeleteLocation={handleDeleteLocation}
          selectedLocationForEdit={selectedLocationForEdit}
        />
      </DetailsContentWrapper>
    </>
  );
};

export default AddEditLocation;
