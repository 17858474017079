import { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import { DarkModeContext } from "@datwyler/shared-components";
import {
  HEADERHEIGHT,
  HEADERHEIGHTMOBILE,
  getNavBarWidth,
  useScreenOptions,
} from "@datwyler/mfe-shared-components";

const DetailsContentWrapper = (props) => {
  const { isOpen, children, handleClose, leftMargin } = props;
  const { colors }: any = useContext(DarkModeContext);
  const { isMobileScreen } = useScreenOptions();

  const navbarWidth = getNavBarWidth();

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          margin: 0,
          maxHeight: `calc(100vh - ${
            isMobileScreen ? HEADERHEIGHTMOBILE : HEADERHEIGHT
          }px - 100px - 32px)`,
          boxShadow: "unset",
          backgroundColor: colors.cardBg,
        },
      }}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "unset",
        },
        "& .MuiDialog-container": {
          justifyContent: "unset",
          alignItems: "unset",
        },
        top: HEADERHEIGHT + 100,
        left: navbarWidth + leftMargin + 404 + 24,
        bottom: "unset",
        right: "unset",
      }}
    >
      {children}
    </Dialog>
  );
};

export default DetailsContentWrapper;
