export const ACTIVE = "ACTIVE";
export const DEACTIVATED = "DEACTIVATED";
export const IN_PROGRESS = "IN_PROGRESS";
export const ESCALATED = "ESCALATED";

export const LAST_CONNECTION = "last_connection";

export const allowedExtensions = [
  ".pdf",
  ".jpg",
  ".jpeg",
  ".png",
  ".xls",
  ".xlsx",
  ".doc",
  ".docx",
  ".csv",
];
