import { useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useIntl } from "react-intl";
import {
  CloseIcon24p,
  BasicButton,
  DarkModeContext,
  useSnackbar,
} from "@datwyler/shared-components";
import { DEACTIVATED } from "../../utils/constants";

const DeleteAttachmentDialog = (props) => {
  const {
    isOpen = false,
    closeDialog,
    attachmentToDelete,
    updateAttachment,
    updateAttachmentResponseData,
    resetUpdateAttachmentData,
    getAttachmentsData,
    tenantId,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    if (updateAttachmentResponseData?.updateAttachment?.attachment) {
      if (
        updateAttachmentResponseData.updateAttachment.attachment.status ===
        DEACTIVATED
      ) {
        enqueueSnackbar(intl.formatMessage({ id: "success_del" }), {
          variant: "warning",
        });
        resetUpdateAttachmentData();
        getAttachmentsData();
        handleClose();
      }
    }
  }, [updateAttachmentResponseData]);

  const handleClose = () => {
    closeDialog();
  };

  const handleConfirmDelete = () => {
    const attachment = {
      id: null,
      name: "",
      description: "",
      status: DEACTIVATED,
      tenant: { id: tenantId },
    };

    attachment.id = attachmentToDelete.id;
    attachment.name = attachmentToDelete.name;
    attachment.description = attachmentToDelete.description;

    updateAttachment({
      variables: { input: attachment },
    });
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "16px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {attachmentToDelete?.name}
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ fontSize: 14, paddingTop: 1, width: 376 }}>
          <Typography
            sx={{
              fontFamily: "NotoSans-Regular",
              fontSize: 14,
              color: colors.fontTitle,
            }}
          >
            {intl.formatMessage({ id: "confirm_delete" })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "flex-start", paddingBottom: "24px" }}
      >
        <Box sx={{ marginLeft: "16px" }}>
          <BasicButton onClick={handleClose} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ position: "absolute", right: "24px" }}>
          <BasicButton
            onClick={handleConfirmDelete}
            variant={"contained"}
            sx={{
              backgroundColor: colors.red500,
              ":hover": {
                backgroundColor: colors.red800,
              },
            }}
          >
            {intl.formatMessage({ id: "delete" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAttachmentDialog;
