import { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { detailsBoxStyle } from "../utils";
import { useIntl } from "react-intl";
import IconButton from "@mui/material/IconButton";
import { handleDownload } from "../../../utils/utils";
import AttachmentInfoDialog from "../../../Attachments/Dialogs/AttachmentInfoDialog";
import AddAttachmentDialog from "../../../Attachments/Dialogs/AddAttachmentDialog";
import { ACTIVE, allowedExtensions } from "../../../utils/constants";
import DeleteAttachmentDialog from "../../../Attachments/Dialogs/DeleteAttachmentDialog";
import {
  DarkModeContext,
  getRequiredDateFormat,
  useAttachment,
  useSnackbar,
  ApiContext,
  DeleteIcon,
  DownloadIcon,
  BasicButton,
} from "@datwyler/shared-components";

const AttachmentTab = (props) => {
  const { siteDetails, tenantId } = props;
  const {
    fetchAttachmentsData,
    fetchAttachments,
    updateAttachment,
    updateAttachmentResponseData,
    resetUpdateAttachmentData,
  } = useAttachment();
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const { uri, apiInstance }: any = useContext(ApiContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isAddAttachmentDialogOpen, setIsAddAttachmentDialogOpen] =
    useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState(null);
  const [attachmentInfo, setAttachmentInfo] = useState(null);

  useEffect(() => {
    callFetchAttachments();
  }, []);

  const callFetchAttachments = () => {
    fetchAttachments({
      variables: {
        tenantId: tenantId,
        page: { number: 0, size: 999999 },
        sort: ["createdOn,desc"],
        filter: [`siteId:${siteDetails?.site?.id}`, `status:` + ACTIVE],
      },
    });
  };

  const typeTypographyStyle = {
    width: "160px",
    color: colors.siteDetailsFont2,
    fontFamily: "NotoSans-Medium",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0.02px",
    lineHeight: "16px",
  };

  const dateTypographyStyle = {
    color: colors.siteDetailsFont1,
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
    letterSpacing: "0.02px",
    lineHeight: "16px",
  };

  const handleCloseAddDialog = () => {
    callFetchAttachments();
    setIsAddAttachmentDialogOpen(false);
  };

  return (
    <Box sx={{ marginTop: "24px" }}>
      {fetchAttachmentsData?.attachments?.attachments?.map(
        (attachment, index) => {
          const fileExtension = attachment?.filename?.split(".").pop();
          const date = getRequiredDateFormat(
            attachment.createdOn,
            "DD/MM/YYYY HH:mm"
          );

          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              key={"attachment_" + index}
            >
              <Box
                sx={{
                  ...detailsBoxStyle,
                  maxWidth: "118px",
                  cursor: "pointer",
                }}
                onMouseDown={() => setAttachmentInfo(attachment)}
              >
                <Typography sx={typeTypographyStyle}>
                  {attachment.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  ...detailsBoxStyle,
                  marginRight: "16px",
                  width: "30px",
                }}
              >
                <Typography sx={dateTypographyStyle}>
                  {fileExtension}
                </Typography>
              </Box>
              <Box
                sx={{
                  ...detailsBoxStyle,
                  marginRight: "16px",
                  maxWidth: "116px",
                }}
              >
                <Typography sx={dateTypographyStyle}>{date}</Typography>
              </Box>
              <Box sx={{ ...detailsBoxStyle, maxWidth: "90px" }}>
                <Box sx={{ display: "inline-flex" }}>
                  <IconButton
                    onClick={() => {
                      handleDownload(
                        attachment,
                        apiInstance,
                        uri,
                        tenantId,
                        enqueueSnackbar
                      );
                    }}
                    size={"small"}
                  >
                    <DownloadIcon fill={colors.iconColor} />
                  </IconButton>
                </Box>
                <Box sx={{ display: "inline-flex" }}>
                  <IconButton
                    onClick={() => {
                      setAttachmentToDelete(attachment);
                    }}
                    size={"small"}
                  >
                    <DeleteIcon fill={colors.iconColor} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          );
        }
      )}
      <Box sx={{ padding: "16px 0px" }}>
        <BasicButton
          sx={{ width: "100%" }}
          onClick={() => {
            setIsAddAttachmentDialogOpen(true);
          }}
        >
          {intl.formatMessage({ id: "add_attachment" })}
        </BasicButton>
      </Box>

      <AttachmentInfoDialog
        isOpen={!!attachmentInfo}
        closeDialog={() => setAttachmentInfo(null)}
        attachmentInfo={attachmentInfo}
      />
      <AddAttachmentDialog
        isOpen={isAddAttachmentDialogOpen}
        closeDialog={handleCloseAddDialog}
        allowedExtensions={allowedExtensions}
        tenantId={tenantId}
        selectedSiteId={siteDetails?.site?.id}
        getAttachmentsData={callFetchAttachments}
      />
      <DeleteAttachmentDialog
        isOpen={!!attachmentToDelete}
        closeDialog={() => {
          setAttachmentToDelete(null);
        }}
        attachmentToDelete={attachmentToDelete}
        updateAttachment={updateAttachment}
        updateAttachmentResponseData={updateAttachmentResponseData}
        resetUpdateAttachmentData={resetUpdateAttachmentData}
        getAttachmentsData={callFetchAttachments}
        tenantId={tenantId}
      />
    </Box>
  );
};

export default AttachmentTab;
